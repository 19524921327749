// extracted by mini-css-extract-plugin
export var heroDiv = "hero-module--hero-div--QI2CS";
export var projectPeekDiv = "hero-module--project-peek-div--O5ZpN";
export var titleDiv = "hero-module--title-div--1Se+k";
export var internalLink = "hero-module--internal-link--DnWw-";
export var highlightText = "hero-module--highlight-text--kt1Ua";
export var offWhiteText = "hero-module--off-white-text--Px-OT";
export var orangeText50Trans = "hero-module--orange-text-50-trans--xf13q";
export var offWhiteTextTrans = "hero-module--off-white-text-trans--wXVEz";
export var aquaTextTrans = "hero-module--aqua-text-trans--dKqhN";
export var greenTextTrans = "hero-module--green-text-trans--u1exu";
export var yellowTextTrans = "hero-module--yellow-text-trans--jvxtv";
export var tab = "hero-module--tab--FvRcE";
export var title = "hero-module--title--+uEef";