// extracted by mini-css-extract-plugin
export var internalLink = "index-module--internal-link--aLiAt";
export var highlightText = "index-module--highlight-text--Ef-V7";
export var offWhiteText = "index-module--off-white-text--j8ed5";
export var orangeText50Trans = "index-module--orange-text-50-trans--Lg0FP";
export var offWhiteTextTrans = "index-module--off-white-text-trans--tJrrA";
export var aquaTextTrans = "index-module--aqua-text-trans--gauVA";
export var greenTextTrans = "index-module--green-text-trans--VZCov";
export var yellowTextTrans = "index-module--yellow-text-trans--9aSKu";
export var tab = "index-module--tab--edgB7";
export var mainDiv = "index-module--main-div--hOy6A";
export var subtitle = "index-module--subtitle--Q3P4u";
export var title = "index-module--title--FsR+z";
export var opener = "index-module--opener--pek2k";
export var basicText = "index-module--basic-text--Hx0Bw";
export var projectLink = "index-module--project-link--T02I+";